import React from 'react'
import CustomIcon from '../main/CustomIcon'

const Modal = ({open, close, children}) => {
  return (
    <>
        <div className={`transition duration-700 ease-in-out  w-full h-screen fixed left-0 top-0 z-10 justify-center items-center bg-black bg-opacity-30 ${open ? 'flex' : 'hidden'}`}>
            <div className="w-96 rounded-2xl bg-white -mt-10 pb-4 px-4">
                <div className="w-full rounded-t-2xl h-11 flex justify-end relative top-1 right-1 " onClick={close}>
                    <CustomIcon icon="close" className="cursor-pointer"/>
                </div>
                <div className="flex justify-center">
                    {children}
                </div>
            </div>
        </div>
    </>
  )
}

export default Modal