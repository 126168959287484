import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function Menu() {
    const navigate = useNavigate()
    const user = useSelector((state) => state.Login.user);
    const [menuItems,setMenuItems] = useState([])
    const location = useLocation();
    useEffect(() => {
        if(user.id){
            setMenuItems(user.models)
        }
    }, [user]);
    
    return (
        <div className="flex flex-col h-screen min-w-60 max-w-60 border border-mts-white bg-mts-white">
            <div className="flex flex-col ">
                {
                    menuItems.map((item, index) => {
                        // Check if the current path is either the main link or one of the associated routes
                        const isActive = location.pathname === item.link || (item.associatedRoutes && item.associatedRoutes.includes(location.pathname));

                        return (
                            <Link to={item.link} key={index} className={
                                `relative flex flex-row justify-between items-center px-4 py-2 hover:bg-o2o-gray-alt hover:underline hover:text-base rounded-lg transition-colors duration-200 ${
                                    isActive ? "bg-blue-500 text-white before:absolute before:left-0 before:top-0 before:bottom-0 before:w-1 before:bg-blue-700" : ""
                                }`
                            }>
                                <div className="w-10">
                                    <span className="material-symbols-outlined">
                                        {item.icon}
                                    </span>
                                </div>
                                <div className="flex-1">{item.name}</div>
                            </Link>
                        );
                    })
                }
            </div>
        </div>
    );
}
