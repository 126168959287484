import React from "react";
import { useSelector } from 'react-redux';

const Header = () => {
    const user = useSelector((state) => state.Login.user);
    console.log(user)
    return (
        <>
            <header className="flex justify-between items-center p-3 border-b bg-mts-white border-mts-white relative ">
                <div className="absolute top-0 left-0 w-full h-1 "></div>
                <div className="relative ml-[300px]">
                    <input type="text" placeholder="Search" className="pl-10 pr-4 py-2 bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500 " />
                    <svg className="fas fa-search absolute left-3 top-3 text-gray-400" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.5">
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.69341 12.5352C12.4232 11.3751 13.6957 8.2216 12.5356 5.49177C11.3754 2.76193 8.22196 1.48945 5.49212 2.6496C2.76229 3.80975 1.48981 6.96321 2.64996 9.69305C3.81011 12.4229 6.96357 13.6954 9.69341 12.5352Z" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.3901 11.3896L15.5555 15.5556" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                    </svg>
                </div>
                <div className="flex items-center space-x-2">
                    <img src={user.imageProfile || "https://placehold.co/40x40"} alt="Profile picture of Miguel Jacob" className="w-10 h-10 rounded-full" />
                    <div>
                        <p className="font-semibold text-gray-700">{user.first_name}</p>
                        <p className="text-sm text-gray-500">{user.role}</p>
                    </div>
                    <i className="fas fa-chevron-down text-gray-500"></i>
                </div>
            </header>
        </>
    )
}

export default Header;