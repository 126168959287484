import React, { useState } from 'react';
import CustomInput from '../../components/form/CustomInput';
import { Link, useNavigate } from 'react-router-dom';
import CustomButtonConfirm from '../../components/form/CustomButtonConfirm';
import { loginSuccess, loginUser } from '../../store/auth/login/action';
import Modal from '../../components/modal/BaseModalAnimated';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from 'react-redux';
import { postLoginToken, resetPassword,/*Funcion parar reset*/ } from '../../helpers/configRoutes/backend_helper'

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [forgotPassword, setForgotPassword] = useState(false);

    const validation = useFormik({
        initialValues: {
            email: '',
            reset: true,
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Correo electrónico no válido").required("Ingresa tu correo electrónico"),
        }),
        onSubmit: async (values, { setErrors }) => {
            setLoading(true)
            const response = await resetPassword(values)
            if (response.status == true) {
                navigate('/login')
            } else {
                if (response.errors?.length) {
                    setErrors(response.errors);
                }
            }
            setLoading(false)
        }
    });

    return (
        <div className="flex justify-center items-center w-screen h-screen bg-mts-blue-primary relative">
            <div className="flex flex-col w-full max-w-md bg-white rounded-[30px] shadow-md pt-[90px] pb-[90px] px-[40px] z-10 sm:pt-[70px] sm:pb-[70px] sm:px-[30px] lg:max-w-lg">
                <div className="text-center text-xl text-gray-800 mb-6">
                    <p>Forgot Password?</p>
                    <p className='text-base font-normal mt-[25px]'>Please enter your email to reset your password</p>
                </div>
                <form className="flex flex-col space-y-4" onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    {forgotPassword && (
                        <>
                            <p className="text-sm text-center font-bold">
                                ¿Olvidaste tu contraseña?
                            </p>
                            <p className="text-sm text-center">
                                Ingresa el correo electrónico asociado a tu cuenta
                            </p>
                        </>
                    )}
                    <div className="flex flex-col ">
                        <CustomInput
                            label="Email Address"
                            type="email"
                            name="email"
                            labelClassName={'text-[18px]'}
                            error={validation.errors.email}
                            value={validation.values.email}
                            handleChange={validation.handleChange}
                        />
                    </div>

                    <div className="flex justify-center items-center p-5">
                        <CustomButtonConfirm
                            loading={loading}
                            textLoad={'Processing'}
                            type="success"
                            className=""
                            text={"Reset password"}
                        />
                    </div>

                    <p className='text-center'>
                        <a
                            className="hover:underline text-center text-sm text-blue-600 cursor-pointer"
                            onClick={() => navigate('/login')}
                        >
                            {' Go back'}
                        </a>
                    </p>
                </form>
            </div>
            <div className="absolute bottom-0 left-0 w-[800px] h-[500px] bg-no-repeat image-login-bl hidden md:block"></div>
            <div className="absolute bottom-0 right-0 w-[550px] h-[600px] bg-no-repeat image-login-br hidden md:block"></div>
            <div className="absolute top-0 left-2 w-[850px] h-[600px] bg-no-repeat image-login-tl hidden lg:block"></div>
            <div className="absolute top-0 right-0 w-[550px] h-[600px] bg-no-repeat image-login-tr hidden lg:block"></div>
        </div>
    );
}

export default ForgotPassword;
