import React, { useState } from 'react';
import CustomInput from '../../components/form/CustomInput';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from "yup";
import { useFormik } from "formik";
import { resetConfirm, } from '../../helpers/configRoutes/backend_helper'
import CustomButton from '../../components/form/CustomButton';

const AsignPassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const {token} = useParams()

    const validation = useFormik({
        initialValues: {
            email: '',
            password: '',
            passwordConfirm: '',
            tokenSms:'',
            token:token
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Correo electrónico no válido")
                .required("Ingresa tu correo electrónico"),
            password: Yup.string()
                .required("Ingresa tu contraseña")
                .min(8, "La contraseña debe tener al menos 8 caracteres")
                .matches(/[A-Z]/, "La contraseña debe contener al menos una letra mayúscula")
                .matches(/[a-z]/, "La contraseña debe contener al menos una letra minúscula")
                .matches(/[0-9]/, "La contraseña debe contener al menos un número"),
            passwordConfirm: Yup.string()
                .oneOf([Yup.ref('password'), null], "Las contraseñas no coinciden")
                .required("Confirma tu contraseña")
        }),
        onSubmit: async (values, { setErrors }) => {
            setLoading(true)
            const response = await resetConfirm(values);
            if (response.status === true) {
                navigate('/login');
            } else {
                if (response.errors?.length) {
                    setErrors(response.errors);
                }
            }
            setLoading(false)
        }
    });


    return (
        <div className="flex justify-center items-center w-screen h-screen bg-mts-blue-primary relative">
            <div className="flex flex-col w-full max-w-md bg-white rounded-[30px] shadow-md pt-[90px] pb-[90px] px-[40px] z-10 sm:pt-[70px] sm:pb-[70px] sm:px-[30px] lg:max-w-lg">
                <div className="text-center text-xl text-gray-800 mb-3">
                    <p>Set your password</p>
                    <p className='text-base font-normal mt-[25px]'>Please enter the new password to log in to your account</p>
                </div>
                <form className="flex flex-col space-y-4" onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <div className="flex flex-col ">
                        <CustomInput
                            label="Email Address"
                            type="email"
                            name="email"
                            labelClassName={'text-[18px]'}
                            error={validation.errors.email}
                            value={validation.values.email}
                            handleChange={validation.handleChange}
                        />
                    </div>
                    <div className="flex w-full space-x-4">
                        <div className="flex flex-col w-1/2">
                            <CustomInput
                                label="Password"
                                type="password"
                                name="password"
                                labelClassName={'text-[18px]'}
                                error={validation.errors.password}
                                value={validation.values.password}
                                handleChange={validation.handleChange}
                            />
                        </div>
                        <div className="flex flex-col w-1/2">
                            <CustomInput
                                label="Confirm Password"
                                type="password"
                                name="passwordConfirm"
                                labelClassName={'text-[18px]'}
                                error={validation.errors.passwordConfirm}
                                value={validation.values.passwordConfirm}
                                handleChange={validation.handleChange}
                            />
                        </div>
                    </div>


                    <div className="flex flex-col ">
                        <CustomInput
                            label="Security number (sms code)"
                            type="tokenSms"
                            name="tokenSms"
                            labelClassName={'text-[18px]'}
                            error={validation.errors.tokenSms}
                            value={validation.values.tokenSms}
                            handleChange={validation.handleChange}
                        />
                    </div>

                    <div className="flex justify-center items-center p-5">
                        <CustomButton
                            loading={loading}
                            textLoad={'Processing'}
                            type="success"
                            className=" w-full"
                            text={"Send"}
                        >
                            Send
                        </CustomButton>
                    </div>

                    <p className='text-center'>
                        <a
                            className="hover:underline text-center text-sm text-blue-600 cursor-pointer"
                            onClick={() => navigate('/login')}
                        >
                            {' Go back'}
                        </a>
                    </p>
                </form>
            </div>
            <div className="absolute bottom-0 left-0 w-[800px] h-[500px] bg-no-repeat image-login-bl hidden md:block"></div>
            <div className="absolute bottom-0 right-0 w-[550px] h-[600px] bg-no-repeat image-login-br hidden md:block"></div>
            <div className="absolute top-0 left-2 w-[850px] h-[600px] bg-no-repeat image-login-tl hidden lg:block"></div>
            <div className="absolute top-0 right-0 w-[550px] h-[600px] bg-no-repeat image-login-tr hidden lg:block"></div>
        </div>
    );
}

export default AsignPassword;
