import React from 'react'
import Modal from '../modal/Modal'
import CustomButtonConfirm from '../form/CustomButtonConfirm'


const Toast = () => {
    //const {isOpen, message, setCloseToastStore} = useToastStore();
    const toast = {isOpen:false,message:''}
    return (
        <Modal open={toast.isOpen} close={() => console.log('sk')}>
            <div className='flex flex-col'>
                <p className='text-xl font-semibold text-center'>{toast.message}</p>
                <br />
                <CustomButtonConfirm text="Aceptar" handleOnClick={() => console.log('')} />
            </div>
        </Modal>
    )
}

export default Toast