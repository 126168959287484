import React from "react";
import BaseModalAnimated from "./BaseModalAnimated";
import CustomButton from "../form/CustomButton";

const ConfirmDeleteModal = ({ action, modal, setModal }) => {
    return (
        <BaseModalAnimated action={action} modal={modal} setModal={setModal}>
            {modal.text ?? 'Are you sure you want to perform this action?'}
            <div className="flex items-center justify-center p-6 rounded-b border-slate-200">
                <CustomButton
                    loading={modal.loading}
                    textLoad={modal.textLoad}
                    type={"success"}
                    onClick={action}
                    className="w-full lg:w-[30%]"
                >
                    {modal.textButton ?? 'Delete'}
                </CustomButton>
                <CustomButton
                    isDisabled={modal.loading}
                    textLoad={'Cancel'}
                    type={"success"}
                    onClick={() => setModal({ ...modal, show: false })}
                    className="w-full lg:w-[30%] bg-mts-red hover:bg-mts-red-hover"
                >
                    {'Cancel'}
                </CustomButton>
            </div>
        </BaseModalAnimated>
    )
}

export default ConfirmDeleteModal;