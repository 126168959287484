import React from "react";
import { Navigate } from "react-router-dom";
import Menu from "../main/Menu";
import { axiosClient } from "../../helpers/axios";
import Header from "../main/Header";
import { useSelector } from "react-redux";

const MiddlewareAuth = ({ children }) => {
  
  const user = useSelector((state) => state.Login.auth);
  console.log(user,'loadsnis')
  const token = '';
  axiosClient.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      if (error.respose.satus == 401) {
        //resetAuthStore()
      }
      return Promise.reject(error)
    }
  );

  if (user && user.isAuth) {
    return (
      <>
      <Header/>
        <div className="flex flex-row max-h-screen">
          <Menu />
          <div className="w-full h-screen overflow-auto bg-mts-gray">
            {children}
          </div>
        </div>
      </>
    )
  } else {
    return <Navigate to="/login" />
  }

  /*if(children.type.name == 'Login'){
  return loggedIn ? children : <Navigate to="/"/>;
}else{
  return loggedIn ? children : <Navigate to="/login"/>
}*/

}

export default MiddlewareAuth