//REGISTER
export const POST_LOGIN_TOKEN = "/login"
export const LOG_OUT = "/logout"
export const RESET_PASSWORD = '/account-reset'
export const RESET_CONFIRM = '/account-confirm'




export const USERS_RESOURCE = '/users'

//catalogos
export const GET_PREFIXES = '/catalogs-cms/prefixes'
export const GET_MODULES = '/catalogs-cms/models'
export const GET_INTERESES = '/catalogos-registro/intereses'
export const GET_ESPECIALIDADES = '/catalogos-registro/especialidades'
export const GET_CUIDADOS = '/catalogos-registro/cuidados'
export const GET_ENTIDADES = '/catalogos-registro/entidades'
export const GET_HABILIDADES = '/catalogos-registro/habilidades'
export const GET_OFERTA_SERVICIOS = '/catalogos-registro/oferta-servicios'
export const GET_TIEMPOS= '/catalogos-registro/turnos-tiempos'






