import React from "react";
const Home = () => {
    return (
        <>
            <div className="p-8 bg-gray-100 min-h-screen">
                {/* Header */}
                <h1 className="text-2xl font-bold mb-6">Dashboard</h1>

                {/* Statistics Cards */}
                <div className="grid grid-cols-4 gap-4 mb-6">
                    <div className="bg-white shadow-md p-4 rounded-lg text-center">
                        <div className="text-gray-500 text-sm">Total New Users</div>
                        <div className="text-3xl font-bold">40,689</div>
                        <div className="text-green-500 mt-2 text-sm">
                            <i className="fas fa-chart-line"></i> 8.5% Up from yesterday
                        </div>
                    </div>
                    <div className="bg-white shadow-md p-4 rounded-lg text-center">
                        <div className="text-gray-500 text-sm">Active Subscriptions</div>
                        <div className="text-3xl font-bold">10,293</div>
                        <div className="text-green-500 mt-2 text-sm">
                            <i className="fas fa-chart-line"></i> 1.3% Up from past week
                        </div>
                    </div>
                    <div className="bg-white shadow-md p-4 rounded-lg text-center">
                        <div className="text-gray-500 text-sm">Total Sales</div>
                        <div className="text-3xl font-bold">$89,000</div>
                        <div className="text-red-500 mt-2 text-sm">
                            <i className="fas fa-arrow-down"></i> 4.3% Down from yesterday
                        </div>
                    </div>
                    <div className="bg-white shadow-md p-4 rounded-lg text-center">
                        <div className="text-gray-500 text-sm">Total Trucks</div>
                        <div className="text-3xl font-bold">285,040</div>
                        <div className="text-green-500 mt-2 text-sm">
                            <i className="fas fa-chart-line"></i> 1.8% Up from yesterday
                        </div>
                    </div>
                </div>

                {/* Sales Details */}
                <div className="bg-white shadow-md p-6 rounded-lg mb-6">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg font-semibold">Sales Details</h2>
                        <select className="p-2 bg-gray-100 rounded-lg">
                            <option>October</option>
                            {/* Additional months if needed */}
                        </select>
                    </div>
                    {/* Placeholder for the chart */}
                    <div className="w-full h-48 bg-gray-200 rounded-lg flex items-center justify-center">
                        <span className="text-lg font-semibold text-gray-500">Chart goes here</span>
                    </div>
                </div>

                {/* Active Subscriptions */}
                <div className="bg-white shadow-md p-6 rounded-lg">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg font-semibold">Active Subscriptions</h2>
                        <select className="p-2 bg-gray-100 rounded-lg">
                            <option>July 2024</option>
                            {/* Additional months if needed */}
                        </select>
                    </div>
                    <table className="min-w-full bg-white">
                        <thead>
                            <tr className="w-full bg-gray-100">
                                <th className="py-2 text-left px-4">Company Name</th>
                                <th className="py-2 text-left px-4">Location</th>
                                <th className="py-2 text-left px-4">Active Since Date - Time</th>
                                <th className="py-2 text-left px-4">Trucks</th>
                                <th className="py-2 text-left px-4">Subscription</th>
                                <th className="py-2 text-left px-4">Subscription Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="py-2 px-4">Transports LLC</td>
                                <td className="py-2 px-4">6096 Marjolaine Landing</td>
                                <td className="py-2 px-4">12.09.2019 - 12.53 PM</td>
                                <td className="py-2 px-4">423</td>
                                <td className="py-2 px-4">$34.99</td>
                                <td className="py-2 px-4">
                                    <span className="bg-mts-green text-white px-2 py-1 rounded-full">Premium</span>
                                </td>
                            </tr>
                            {/* Repeat for more entries */}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default Home