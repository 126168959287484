import { useNavigate } from "react-router-dom";

export default function CustomInput({ forgotPassword, label, value, name, type, handleChange, error, className, labelClassName, ...props }) {
    const buttonClassName = `border pl-4 rounded-md h-12 ${error ? 'border-red-600' : 'border-black'} bg-mts-gray ${className}`;
    const labelBtnClassName = `font-normal text-sm ${labelClassName}`;
    const navigate = useNavigate()

    const ErrorMensaje = () => {
        if (error) {
            return (
                <span className="text-red-600">
                    {error}
                </span>
            )
        }
        return
    }
    return (
        <div className="flex flex-col w-full py-1">
            <label className={labelClassName}>{label}</label>

            {forgotPassword != undefined && (
                <label className={`${labelClassName} text-right`} onClick={() => navigate('/forgotPassword')}>
                    Forget Password?
                </label>
            )}
            <input
                type={type}
                value={value}
                name={name}
                onChange={handleChange}
                className={buttonClassName}
                {...props}
            />

            <ErrorMensaje />
        </div>

    );
}