import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  user: JSON.parse(localStorage.getItem("authUser"))?.user || {},
  auth: {
    token: JSON.parse(localStorage.getItem("authUser"))?.token || "",
    isAuth: JSON.parse(localStorage.getItem("authUser"))?.isAuth || false,
  },
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
      };
      
    case LOGIN_SUCCESS:
      const { user, token, isAuth } = action.payload;

      // Guarda los datos en localStorage
      localStorage.setItem("authUser", JSON.stringify({ user, token, isAuth }));

      // Actualiza el estado con los datos directamente desde el payload
      return {
        ...state,
        user: user, // Actualizamos el estado con el nuevo usuario
        auth: {
          token: token,
          isAuth: isAuth,
        },
        loading: false,
      };

    case LOGOUT_USER:
      return {
        ...state,
      };
      
    case LOGOUT_USER_SUCCESS:
      // Elimina los datos de localStorage y resetea el estado
      localStorage.removeItem("authUser");
      return {
        ...state,
        user: {},
        auth: {
          token: "",
          isAuth: false,
        },
      };
      
    case API_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
      
    default:
      return state;
  }
};

export default login;
