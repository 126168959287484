const CustomIcon = ({ icon, className = '' }) => {
    return (
        <div className={className}>
            <span className="material-symbols-outlined">
                {icon}
            </span>
        </div>
    )
}

export default CustomIcon